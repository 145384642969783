<template>
  <div id="complaintCategroies">
    <b-row>
      <b-col md="4">
        <b-card
          :data-mode="formMode"
          :title="formMode == 'add' ? 'Add New Reason' : 'Update Reason'"
        >
          <div
            v-if="msgForm.message"
            :class="{
              alert: true,
              'p-1': true,
              'alert-danger': msgForm.type == 'danger',
              'alert-success': msgForm.type == 'success',
            }"
          >
            {{ msgForm.message }}
          </div>
          <b-form-group label="Reason *">
            <b-form-input v-model="reason" />
          </b-form-group>
          <b-form-group label="Reason (Arabic) *">
            <b-form-input v-model="reasonAr" />
          </b-form-group>
          <b-row>
            <b-col :cols="formMode == 'add' ? 12 : 6"
              ><b-button
                :disabled="isSaving || reason == ''"
                v-on:click="saveReason"
                block
                variant="primary"
                >{{ isSaving ? "Please wait.." : formMode == "add" ? "Add" : "Update" }}</b-button
              ></b-col
            >
            <b-col cols="6" v-if="formMode != 'add'">
              <b-button variant="danger" block @click="cancelEdit()">Cancel</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card header="Reasons" no-body>
          <b-card-body>
            <b-form-input placeholder="Filter Reasons" v-model="reasonsFilterText" />
          </b-card-body>
          <b-table
            ref="reasonsTable"
            :fields="reasonTableColumns"
            :items="loadReasons"
            show-empty
            :empty-text="isLoading ? 'Loading...' : 'Record not found'"
          >
            <template #cell(reason)="data">
              <div>
                <b>{{ data.item.reason }}</b>
                <br />
                <small>{{ data.item.reasonArabic }}</small>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="warning"
                size="sm"
                @click="editReason(data.item._id, data.item.reason, data.item.reasonArabic)"
                class="mr-1"
                :disabled="formMode == 'edit'"
                >Edit</b-button
              >
              <b-button
                variant="danger"
                :disabled="formMode == 'edit'"
                size="sm"
                @click="deleteReason(data.item._id)"
                >Delete</b-button
              >
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BFormInput,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import useComplaints from "./useComplaints";
import axios from "axios";
import { ref } from "@vue/composition-api";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BFormGroup,
    BButton,
    BCardBody,
  },
  data() {
    return {};
  },
  methods: {
    deleteReason: async function (reasonId) {
      if (confirm("Are you sure you want to delete this reason")) {
        try {
          const res = await axios.delete(`complaints/deleteReason/${reasonId}`);
          this.reasonsTable.refresh();
        } catch (e) {
          alert(e.message || "unabel to delete reason");
        }
      }
    },
  },
  setup() {
    const { reasonsTable, reasonTableColumns, reasonsFilterText, loadReasons, isLoading } =
      useComplaints();

    const reason = ref("");
    const reasonAr = ref("");
    const isSaving = ref(false);
    const msgForm = ref({});
    const formMode = ref("add");
    const reasonId = ref("");

    const cancelEdit = () => {
      formMode.value = "add";
      reason.value = "";
      reasonAr.value = "";
    };

    const saveReason = async () => {
      isSaving.value = true;
      msgForm.value = {};

      try {
        if (formMode.value == "add") {
          const formData = new FormData();
          formData.append("reason", reason.value);
          formData.append("reasonArabic", reasonAr.value);

          const response = await axios.post("complaints/createReason", formData);
          if (response.status == 200) {
            msgForm.value = {
              message: "Reason saved successfully",
              type: "success",
            };
          }
        } else {
          const formData = new FormData();
          formData.append("reason", reason.value);
          formData.append("reasonArabic", reasonAr.value);
          formData.append("reasonId", reasonId.value);

          const response = await axios.put("complaints/updateReason", formData);
          if (response.status == 200) {
            msgForm.value = {
              message: "Reason updated successfully",
              type: "success",
            };
          }
        }

        reason.value = "";
        reasonAr.value = "";
        reasonsTable.value.refresh();

        formMode.value = "add";
        isSaving.value = false;
      } catch (error) {
        isSaving.value = false;
        msgForm.value = {
          message: error.response.data.error.message || "Something went wrong",
          type: "danger",
        };
      }
    };

    const editReason = (id, reasonIp, reasonArip) => {
      formMode.value = "edit";
      msgForm.value = "";
      reasonId.value = id;
      reason.value = reasonIp;
      reasonAr.value = reasonArip;
    };

    return {
      reasonsTable,
      reasonTableColumns,
      reasonsFilterText,
      loadReasons,
      reason,
      reasonAr,
      isSaving,
      msgForm,
      saveReason,
      formMode,
      reasonId,
      editReason,
      cancelEdit,
      isLoading,
    };
  },
};
</script>
